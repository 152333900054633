import React from "react";
import { FieldErrors } from "react-hook-form";

export interface FormDetails {
  control: any;
  errors: FieldErrors;
  setFocus: any;
  isSubmitting: boolean;
  setValue: any;
}

export const FormContext = React.createContext({} as FormDetails);

export const FormContextProvider = ({ children, formDetails }: { children: React.ReactNode, formDetails: FormDetails }) => {
  return (
    <FormContext.Provider value={formDetails}>
      {children}
    </FormContext.Provider>
  );
};