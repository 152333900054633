export const AUTHORISE_APP_ACCESS_URL = "/v1/auth";
export const AUTHORISE_APP_ACCESS_BASIS_SESSION_URL = (appId: string) => {
  return "/v1/auth/session?applicationId=" + appId;
};

const BASE_APP_API_URL = "/v1/application/";

export const PROCESS_APP_URL = (appId: number) => {
  return BASE_APP_API_URL + appId;
};
export const FETCH_APP_DETAILS_URL = (appId: string) => {
  return PROCESS_APP_URL(Number(appId)) + "/details";
};
export const PROCESS_REDIRECT_URL = (appId: number, partner: string) => {
  return "/v1/redirect/" + partner + "?referenceId=" + appId;
};
export const REPAYMENT_SCHEDULE_URL = (appId: number) => {
  return PROCESS_APP_URL(appId) + "/kfs/repaymentSchedule";
};
export const ACTION_APP_URL = (appId: number) => {
  return PROCESS_APP_URL(appId) + "/action";
};
export const FETCH_APP_CONGRATS_MSG_URL = (appId: number) => {
  return ACTION_APP_URL(appId) + "/congratulations";
};
export const ADD_APP_EVENT_URL = (appId: number) => {
  return PROCESS_APP_URL(appId) + "/event";
};
