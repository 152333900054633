import React, { useCallback, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { AuthenticationBasisSession } from "../../../Authentication/components/AuthenticationComponent";
import { AppDetails } from "../../../Common/models/AppDetails";
import { AuthResponse } from "../../../Authentication/models/AuthResponse";
import { ProcessRedirectEntry } from "./RedirectEntryProcessor";
import { ErrorScreen } from "../../../Common/viewComponents/ErrorScreen";

export const ApplicationRedirectEntryWrapper: React.FC = () => {
  const { callbackSource } = useParams();
  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const referenceId = searchParams.get("referenceId");

  const [appDetails, setAppDetails] = useState<AppDetails | null>(null);

  const authCallback = useCallback((authResponse: AuthResponse) => {
    setAppDetails(authResponse?.appDetails);
    if (authResponse?.appDetails?.applicationId !== null && authResponse?.appDetails?.applicationId !== undefined) {
      sessionStorage.setItem("AUTHORISED", authResponse.appDetails.applicationId.toString());
    }
    setAuthenticated(authResponse?.result);
  }, []);

  if (callbackSource === undefined) {
    return <ErrorScreen msg="Please reattempt using link sent in mail" />;
  } else if (authenticated) {
    return <ProcessRedirectEntry applicationId={appDetails?.applicationId} partner={callbackSource} />;
  } else {
    return <AuthenticationBasisSession appId={referenceId} token={null} authenticated={authenticated} onAuthentication={authCallback} />;
  }
};