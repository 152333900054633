import React, { useCallback, useEffect, useState } from "react";
import { Popup } from "@bb/wc-ui-framework";

interface TooltipProps {
  content: string;
  trigger: any;
  rerender: number;
}

export const Tooltip: React.FC<TooltipProps> = (props) => {
  const [open, setOpen] = useState<boolean>(true);
  const [timer, setTimer] = useState<number>(1);
  const timeOutCallback = useCallback(() => setTimer((currTimer) => currTimer - 1), []);

  const closePopup = () => {
    if (timer > 0) {
      return;
    }
    setOpen(false);
  };

  useEffect(() => {
    if (timer > 0) {
      setTimeout(timeOutCallback, 1000);
    }
  }, [timer, timeOutCallback]);

  useEffect(() => {
    if (!open) {
      setOpen(true);
    }
    setTimer(1);
  }, [props.rerender]);

  return <Popup onClose={closePopup} open={open} {...getProps(props)} />;
};

const getProps = (props: TooltipProps) => {
  let updatedProps: any = {};
  updatedProps["className"] = "green";
  updatedProps["type"] = "popup";
  updatedProps["inverted"] = true;
  updatedProps["on"] = "click";
  updatedProps["pinned"] = true;
  updatedProps["position"] = "bottom center";
  updatedProps["pointing"] = "top";
  updatedProps["style"] = { maxWidth: "fit-content" };
  updatedProps["hideOnScroll"] = true; //With always open, ensures that the pop up is repositioned on scroll
  updatedProps = { ...updatedProps, ...props };
  return updatedProps;
};