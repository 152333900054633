import React, { useCallback, useContext, useEffect, useState } from "react";
import { FormContextProvider, FormDetails } from "../../../../Common/contexts/FormContext";
import ActionAreaLayout from "../../../../Common/layouts/ActionAreaLayout";
import { BaseLayoutWithBar } from "../../../../Common/layouts/GenericLayout";
import { useForm } from "react-hook-form";
import { AppContext } from "../../../../Common/contexts/AppContext";
import { AppDetails } from "../../../../Common/models/AppDetails";
import { processFlowEventEmitter } from "../../../EventEmitter";
import AXIOS from "../../../../Common/util/ApiUtils";
import { ACTION_APP_URL } from "../../../../Common/util/UrlConstants";
import { CodeVerificationResponse } from "../../../common/models/CodeVerificationResponse";
import { PartnerCodeVerificationFormFields } from "../segments/PartnerCodeVerificationFormFields";
import { Loading } from "../../../../Common/viewComponents/Loading";
import { ErrorScreen } from "../../../../Common/viewComponents/ErrorScreen";

type FormValues = {
  otp: string
}

async function executeCodeVerifAction(appId: number, otp: number) {
  const response = await AXIOS.post<CodeVerificationResponse>(ACTION_APP_URL(appId) + "/partner-code/verify", { "code": otp }).then((res) => { return res; }).catch(() => { return { data: null }; });
  return response.data;
}

async function initiateCodeVerifFlow(appId: number) {
  const response = await AXIOS.post<CodeVerificationResponse>(ACTION_APP_URL(appId) + "/partner-code/initiate?context=").then((res) => { return res; }).catch(() => { return { data: { result: "ERROR" } }; });
  return response.data;
}

export const PartnerCodeVerification: React.FC = () => {

  const [flowInitiated, setFlowInitiated] = useState<boolean>(false);
  const [processed, setProcessed] = useState<boolean>(false);
  const [codeFlowInit, setCodeFlowInit] = useState<CodeVerificationResponse>();

  const appDetails: AppDetails = useContext(AppContext);
  useEffect(() => {
    initiateCodeVerifFlow(appDetails.applicationId).then((res) => {
      setFlowInitiated("SUCCESS" === res?.result);
      setCodeFlowInit(res);
      setProcessed(true);
    });
  }, []);

  const form = useForm<FormValues>({ mode: "onSubmit" });
  const { control, handleSubmit, setFocus, setError, setValue, formState: { errors, isSubmitting } } = form;
  const formSubmitCallback = useCallback(async () => {
    await handleSubmit(onClickHandler)();
  }, []);

  const onClickHandler = async (data: any) => {
    const response = await executeCodeVerifAction(appDetails.applicationId, data.otp);

    if (response?.result === "SUCCESS") {
      processFlowEventEmitter.emit("STEP_COMPLETED", "PARTNER_CODE_VERIFICATION");
    } else {
      setError("otp", { message: response?.errorMessage });
    }
  };

  const formDetails: FormDetails = {
    control: control,
    errors: errors,
    setFocus: setFocus,
    isSubmitting: isSubmitting,
    setValue: setValue
  };
  if (processed) {
    if (flowInitiated) {
      return (
        <div data-testid="partnercodeverification">
          <BaseLayoutWithBar>
            <ActionAreaLayout
              header="Verify Your Mobile Number"
              buttonLabel="Submit"
              onClick={formSubmitCallback}
              isSubmitting={isSubmitting}>
              <>
                <FormContextProvider formDetails={formDetails}>
                  <PartnerCodeVerificationFormFields formSubmitCallback={formSubmitCallback} />
                </FormContextProvider>
                <br />
                <div className="text-align-center mb-xl">
                  By clicking the &quot;submit&quot; button, you accept the Terms and Conditions of {appDetails.bankName} {appDetails.productTypeName}.
                </div>
              </>
            </ActionAreaLayout>
          </BaseLayoutWithBar>
        </div>
      );
    } else {
      return <ErrorScreen msg={codeFlowInit?.errorMessage || "Error Triggering Code Verification"} />;
    }
  } else {
    return <Loading />;
  }
};