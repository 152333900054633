import React, { useEffect, useRef, ReactNode } from "react";
import { addEvent } from "../util/ApiUtils";

interface FormProps {
  partnerFormParams: any,
  action: string,
  appId: any;
}

interface InputProps {
  key: string,
  name: string,
  value: string,
  appId: any;
}

const HiddenInput: React.FC<InputProps> = (props) => {
  const { name, value } = props;

  return (<input data-testid={"hiddeninput_" + name} type="hidden" name={name} value={value} />);
};


export const HiddenForm: React.FC<FormProps> = (props) => {
  const formRef = useRef(null);
  useEffect(() => {
    try {
      if (formRef !== null && formRef.current !== null && formRef.current !== undefined) {
        addEvent(props.appId, {"eventGroup":"POST_APP_FLOW", "eventType":"PARTNER_REDIRECT_CLIENT", "description":"FORM_SUBMIT_TRIGGERED"});
        (formRef.current as any).submit();
      }
    } catch (e) {
      addEvent(props.appId, {"eventGroup":"POST_APP_FLOW", "eventType":"PARTNER_REDIRECT_CLIENT", "description":"FORM_SUBMIT_FAILED"});
    }
  }, []);

  const formInputs: ReactNode = Object.entries(props.partnerFormParams).map(([key, value]: any) => {
    return (<HiddenInput key={key} name={key} value={value} appId={props.appId} />);
  });

  return (
    <form ref={formRef} action={props.action} method="POST" data-testid="hiddenform">
      {formInputs}
    </form>
  );
};