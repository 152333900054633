import React, { useContext } from "react";
import { Table, Header } from "@bb/wc-ui-framework";
import { KfsDocument } from "../../models/KfsDocument";
import { Row, renderBodyRow } from "../../../../Common/util/TableRenderer";
import { TableContext } from "../../../../Common/contexts/TableContext";

interface PropTypes {
  active: boolean;
  kfsDocument: KfsDocument;
}

export const ContingentChargesView = (props: PropTypes) => {
  const tableContext = useContext(TableContext);
  const widths = tableContext.widths;

  const getContingentCharges = (kfsDocumentDetail: KfsDocument) => {
    const rows: Array<Row> = [
      {
        columns: [
          { text: "Bounce Charge", width: widths[1] },
          { text: kfsDocumentDetail.bounceCharge, width: widths[2] }]
      },
      {
        columns: [
          { text: "Late Payment Charge", width: widths[1] },
          { text: kfsDocumentDetail.latePayCharge, width: widths[2] }]
      },
      {
        columns: [
          { text: "Foreclosure Charge", width: widths[1] },
          { text: kfsDocumentDetail.foreclosureCharge, width: widths[2] }]
      },
      {
        columns: [
          { text: "Overdue Charges", width: widths[1] },
          { text: kfsDocumentDetail.overdueCharges, width: widths[2] }]
      }
    ];
    return rows;
  };

  return (
    <div hidden={props.active}>
      <Header as='h4' content="Details about contingent charges" className="mt-xl" />
      <Table celled columns={tableContext.columns}
        tableData={getContingentCharges(props.kfsDocument)}
        renderBodyRow={renderBodyRow}
      />
    </div>
  );
};