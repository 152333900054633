import React, { useContext, useEffect, useState } from "react";
import { RedirectActionResponse } from "../models/RedirectActionResponse";
import AXIOS, { addEvent } from "../../../Common/util/ApiUtils";
import { ACTION_APP_URL } from "../../../Common/util/UrlConstants";
import { HiddenForm } from "../../../Common/components/HiddenForm";
import { PartnerRedirectionLoader } from "../../../Common/viewComponents/Loading";
import { AppContext } from "../../../Common/contexts/AppContext";
import { ErrorScreen } from "../../../Common/viewComponents/ErrorScreen";


async function executeAction(appId: number) {
  const response = await AXIOS.post<RedirectActionResponse>(ACTION_APP_URL(appId) + "/partner-redirect-form-submit").then((res) => { return res; }).catch(() => { return { data: null }; });
  return response.data;
}

export const PartnerRedirectWithFormSubmit: React.FC = () => {

  const appDetails = useContext(AppContext);
  const [processed, setProcessed] = useState<boolean>(false);
  const [actionResponse, setActionResponse] = useState<RedirectActionResponse | null>(null);

  useEffect(() => {
    executeAction(appDetails.applicationId).then((res) => {
      setActionResponse(res);
      setProcessed(true);
    });
  }, [appDetails.applicationId]);

  if (actionResponse !== null && actionResponse.redirectUrl !== null && actionResponse.redirectUrl !== undefined) {
    addEvent(appDetails.applicationId, {"eventGroup":"POST_APP_FLOW", "eventType":"PARTNER_REDIRECT_CLIENT", "description":"HIDDEN_FORM_RENDERED"});
    return <HiddenForm action={actionResponse.redirectUrl} partnerFormParams={actionResponse.parameters} appId={appDetails.applicationId} />;
  } if (processed) {
    addEvent(appDetails.applicationId, {"eventGroup":"POST_APP_FLOW", "eventType":"PARTNER_REDIRECT_CLIENT", "description":"HIDDEN_FORM_RENDER_FAILED"});
    return <ErrorScreen msg="Could not proceed to partner website. Please reach out to customer support." />;
  } else {
    addEvent(appDetails.applicationId, {"eventGroup":"POST_APP_FLOW", "eventType":"PARTNER_REDIRECT_CLIENT", "description":"PARTNER_REDIRECTION_LOADER"});
    return <PartnerRedirectionLoader partnerId={appDetails.bankId} />;
  }
};