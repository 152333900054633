import React, { useContext, useEffect, useRef } from "react";
import { FormContext, FormDetails } from "../contexts/FormContext";
import { Input } from "@bb/wc-ui-framework";
import { Controller } from "react-hook-form";
import { ErrorLabel } from "../viewComponents/ErrorScreen";

interface TextInputProps {
  layoutProps: LayoutProps;
  rulesProps?: RulesProps;
}

export interface LayoutProps {
  name: string;
  value?: any;
  type?: "text" | "tel" | "phone" | "email" | "password";
  placeholder?: string;
  size?: any;
  minLength?: number;
  maxLength?: number;
  className?: string;
  style?: any;
  icon?: any;
  iconPosition?: string;
  fluid?: any;
  allowedCharset?: string;
  disabled?: boolean;
  transparent?: boolean;
}

interface InputInterface {
  onKeyPress?: any;
  onPaste?: any;
  onKeyUp?: any;
  onKeyDown?: any;
}

interface RulesProps {
  rules?: any;
}

const validateKeyboardInputEvent = (event: any, pattern: any, data: string) => {
  if (!pattern.test(data)) {
    event.preventDefault();
  }
};

//Impl for Chrome mobile - as keypress event is deprecated
//On keyUp, due to bug in chrome, the key code is not returned + event prevent does not work, so we manually check the value input, 
//and update the value to previous value in both form and display div.
const handleKeyUpEvent = (event: any, pattern: any, formContext: FormDetails, fieldName: string) => {
  const text = event.target.value;
  if (text && !pattern.test(text)) {
    const newVal = text.slice(0, -1);
    formContext.setValue(fieldName, newVal);
  }
};

export const TextInput: React.FC<TextInputProps> = (props) => {

  const ref = useRef();
  const { layoutProps, rulesProps } = props;
  const formContext = useContext(FormContext);

  const preventNonNumericInputForTelField = (event: any) => {
    const pattern = /^\d+$/;
    if (event.type === "paste") {
      validateKeyboardInputEvent(event, pattern, event.clipboardData.getData("Text"));
    } else if (event.type === "keypress") {
      validateKeyboardInputEvent(event, pattern, event.key);
    } else if (event.type === "keyup") {
      handleKeyUpEvent(event, pattern, formContext, layoutProps.name);
    }
  };

  const inputInterface: InputInterface = {};
  if (layoutProps.type === "tel") {
    inputInterface["onKeyPress"] = preventNonNumericInputForTelField;
    inputInterface["onPaste"] = preventNonNumericInputForTelField;
    inputInterface["onKeyUp"] = preventNonNumericInputForTelField;
  }

  const validationError = formContext.errors[layoutProps.name];

  useEffect(() => {
    if (validationError) {
      (ref?.current as any)?.scrollIntoView();
    }
  }, [validationError]);

  return (
    <>
      <Controller
        name={layoutProps.name}
        control={formContext.control}
        rules={rulesProps?.rules}
        render={({ field }) => {
          return <Input {...field}
            {...layoutProps}
            {...inputInterface}
            iconPosition='left'
            size={layoutProps.size}
            disabled={layoutProps.disabled}
            style={layoutProps.style}
            icon={layoutProps.icon}
            fluid={layoutProps.fluid}
            transparent={layoutProps.transparent}
            ref={ref}
          />;
        }}
      />
      {validationError && <ErrorLabel content={(validationError as any).message} />}
    </>
  );
};