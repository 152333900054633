import React from "react";
import { AppDetails } from "../models/AppDetails";

export interface AppViewParams {
  pageTitle: string;
  setPageTitle: (title: string) => void;
  displayName: string | undefined;
  setDisplayName: (displayName: string | undefined) => void;
  displayLoader: boolean;
  setDisplayLoader: (displayLoader: boolean) => void
}

export const AppContext = React.createContext({} as AppDetails);

export const AppContextProvider = ({ children, appDetails }: { children: React.ReactNode, appDetails: AppDetails }) => {
  return (
    <AppContext.Provider value={appDetails}>
      {children}
    </AppContext.Provider>
  );
};