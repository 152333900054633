import React, { useContext, useEffect, useState } from "react";
import { CongratulationsScreen } from "../../Common/viewComponents/CongratulationsScreen";
import { AppContext } from "../../Common/contexts/AppContext";
import AXIOS from "../../Common/util/ApiUtils";
import { FETCH_APP_CONGRATS_MSG_URL } from "../../Common/util/UrlConstants";
import { Loading } from "../../Common/viewComponents/Loading";

export interface CongratulationsResponse {
  heading?: string
  subject: string;
  message: string;
  subtext?: string;
}

async function fetchCongratsMessage(applicationId: number) {
  const response = await AXIOS.get<CongratulationsResponse>(FETCH_APP_CONGRATS_MSG_URL(applicationId));
  return response.data;
}

export const Congratulations: React.FC = () => {
  const context = useContext(AppContext);
  const [fetchedDetails, setFetchedDetails] = useState<boolean>(false);
  const [congratulationsResponse, setCongratulationsResponse] = useState<CongratulationsResponse>({ subject: "", message: "" });

  useEffect(() => {
    if (!fetchedDetails) {
      fetchCongratsMessage(context.applicationId).then((res) => {
        setCongratulationsResponse(res);
        setFetchedDetails(true);
      });
    }
  }, []);

  if (fetchedDetails) {
    return <CongratulationsScreen
      heading={congratulationsResponse?.heading}
      subject={congratulationsResponse?.subject}
      message={congratulationsResponse?.message}
      subtext={congratulationsResponse?.subtext} />;
  } else {
    return <Loading />;
  }
};