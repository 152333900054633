import React, { useEffect, useState } from "react";
import { RedirectForm } from "../../../Common/components/RedirectForm";
import { Loading } from "../../../Common/viewComponents/Loading";
import { AXIOS } from "../../../Common/util/ApiUtils";
import { PROCESS_REDIRECT_URL } from "../../../Common/util/UrlConstants";
import { ErrorScreen } from "../../../Common/viewComponents/ErrorScreen";

interface RedirectEntryProps {
  applicationId: number | undefined;
  partner: string;
}

async function processPartnerRedirect(applicationId: number, partner: string) {
  await AXIOS.post(PROCESS_REDIRECT_URL(applicationId, partner));
}

export const ProcessRedirectEntry: React.FC<RedirectEntryProps> = (props) => {

  const [processed, setProcessed] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const applicationId = props.applicationId;

  useEffect(() => {
    if (!processed) {
      if (applicationId !== undefined) {
        processPartnerRedirect(applicationId, props.partner).then(() => {
          setProcessed(true);
        }).catch((err) => {
          setProcessed(true);
          setError(true);
          console.log("Error - " + err);
        });
      } else {
        setProcessed(true);
        setError(true);
      }
    }
  }, [processed]);

  if (error) {
    return <ErrorScreen msg="Please retry using link sent via email after some time." />;
  } else if (processed) {
    return <RedirectForm action={"/app/" + applicationId} />;
  } else {
    return <Loading />;
  }

};