import React, { useContext, useEffect, useState } from "react";
import { RedirectActionResponse } from "../models/RedirectActionResponse";
import AXIOS from "../../../Common/util/ApiUtils";
import { ACTION_APP_URL } from "../../../Common/util/UrlConstants";
import { KycRedirectLoader } from "../../../Common/viewComponents/Loading";
import { RedirectForm } from "../../../Common/components/RedirectForm";
import { AppContext } from "../../../Common/contexts/AppContext";
import { ErrorScreen } from "../../../Common/viewComponents/ErrorScreen";

async function executeAction(appId: number) {
  const response = await AXIOS.post<RedirectActionResponse>(ACTION_APP_URL(appId) + "/kyc-redirect").then((res) => { return res; }).catch(() => { return { data: null }; });
  return response.data;
}

export const KycRedirect: React.FC = () => {

  const appDetails = useContext(AppContext);
  const [processed, setProcessed] = useState<boolean>(false);
  const [actionResponse, setActionResponse] = useState<RedirectActionResponse | null>(null);

  useEffect(() => {
    executeAction(appDetails.applicationId).then((res) => {
      setActionResponse(res);
      setProcessed(true);
    });
  }, [appDetails.applicationId]);

  if (actionResponse !== null && actionResponse.redirectUrl !== null && actionResponse.redirectUrl !== undefined) {
    return <RedirectForm action={actionResponse.redirectUrl} />;
  } if (processed) {
    return <ErrorScreen msg="Error proceeding for KYC. Please try again after some time." />;
  } else {
    return <KycRedirectLoader />;
  }
};