import React, { useState } from "react";
import { RepaymentSchedule } from "../../models/RepaymentSchedule";
import { RepaymentScheduleModal } from "./RepaymentScheduleModal";

interface PropTypes {
  active: boolean;
  repaymentSchedule: Array<RepaymentSchedule>;
}

export const DisclaimerView: React.FC<PropTypes> = (props) => {
  const [showRepaymentModal, setShowRepaymentModal] = useState<boolean>(false);

  function onClose() {
    setShowRepaymentModal(false);
  }

  function handleViewRepaymentScheduleClick() {
    setShowRepaymentModal(true);
  }

  return (
    <div className="text-align-center" hidden={props.active}>
      <p className="mt-xl mb-xl font-large">
        <a href="#" onClick={handleViewRepaymentScheduleClick} rel="noreferrer">View Repayment Schedule</a>
      </p>
      {showRepaymentModal &&
        <RepaymentScheduleModal onClose={onClose} open={showRepaymentModal} repaymentSchedule={props.repaymentSchedule} />}
    </div>
  );
};