import AXIOS from "../../../Common/util/ApiUtils";
import React, { useContext, useEffect, useState } from "react";
import { Loading } from "../../../Common/viewComponents/Loading";
import { ProcessResponse } from "../models/ProcessResponse";
import { PartnerRedirectWithFormSubmit } from "../../redirects/components/PartnerRedirectWithFormSubmit";
import { MandateRedirect } from "../../redirects/components/MandateRedirect";
import { KycRedirect } from "../../redirects/components/KycRedirect";
import { PROCESS_APP_URL } from "../../../Common/util/UrlConstants";
import { AppContext } from "../../../Common/contexts/AppContext";
import { KfsFlow } from "../../kfsFlow/components/KfsFlow";
import { Congratulations } from "../../congratulations/Congratulations";
import { processFlowEventEmitter } from "../../EventEmitter";
import { ErrorScreen } from "../../../Common/viewComponents/ErrorScreen";
import { PartnerCodeVerification } from "../../partnerCodeVerification/views/base/PartnerCodeVerification";

async function processApp(appId: number) {
  const response = await AXIOS.post<ProcessResponse>(PROCESS_APP_URL(appId))
    .then((res) => { return res; })
    .catch((err) => {
      console.error("error - " + err);
      return {
        data: { action: null }
      };
    });
  return response.data;
}

export const Process: React.FC = () => {

  const appDetails = useContext(AppContext);
  const [nextAction, setNextAction] = useState<string | null>(null);
  const [processed, setProcessed] = useState<boolean>(false);

  const appId = appDetails.applicationId;

  useEffect(() => {
    processApp(appId).then((res) => {
      setNextAction(res.action);
      setProcessed(true);
    });

    processFlowEventEmitter.on("STEP_COMPLETED", (completedStep: string) => {
      console.log(`step completed - ${completedStep}`);
      setProcessed(false);
      processApp(appId).then((res) => {
        setNextAction(res.action);
        setProcessed(true);
      });
    });
    //TODO : Add event emitter listener - trigger process in case of events.
  }, [appId]);

  //TODO : Pass appId and action to call specific backend and get
  if (processed) {
    if (nextAction === "PARTNER_REDIRECT_WITH_HIDDEN_FORM_SUBMIT") {
      return (<PartnerRedirectWithFormSubmit />);
    } else if (nextAction === "KYC_REDIRECT") {
      return (<KycRedirect />);
    } else if (nextAction === "PARTNER_CODE_VERIFICATION") {
      return (<PartnerCodeVerification />);
    } else if (nextAction === "MANDATE_REDIRECT") {
      return (<MandateRedirect />);
    } else if (nextAction === "KFS_FLOW") {
      return (<KfsFlow />);
    } else if (nextAction === "CONGRATULATIONS") {
      return (<Congratulations />);
    } else {
      return <ErrorScreen msg="Somethings missing. Please reach out to Customer Service." />;
    }
  } else {
    return <Loading />;
  }
};