import React from "react";

interface TableDetails {
  widths: Array<number>;
  columns: number;
}

export const TableContext = React.createContext({} as TableDetails);

export const TableContextProvider = ({ children, tableDetails }: { children: React.ReactNode, tableDetails: TableDetails }) => {
  return (
    <TableContext.Provider value={tableDetails}>
      {children}
    </TableContext.Provider>
  );
};