import { Locale } from "@bb/wc-utils";
import Parser from "html-react-parser";

export const getFormattedNumberInCurrency = (number: number) => {
  return Locale.current.currencySymbolInUnicode + " " + Locale.current.formatNumber(number);
};

export const toHTMLText = (inStr?: string) => {
  if (!inStr) {
    return undefined;
  }
  return Parser(inStr.replace(/(?:\r\n|\r|\n)/g, "<br />"));
};