import React, { useCallback, useContext } from "react";
import { BaseLayoutWithBar } from "../../../../Common/layouts/GenericLayout";
import { KfsDocumentProps, KfsTable } from "../segments/KfsTable";
import { KfsFormFields } from "../segments/KfsFormFields";
import ActionAreaLayout from "../../../../Common/layouts/ActionAreaLayout";
import { useForm } from "react-hook-form";
import { FormContextProvider, FormDetails } from "../../../../Common/contexts/FormContext";
import AXIOS from "../../../../Common/util/ApiUtils";
import { ACTION_APP_URL } from "../../../../Common/util/UrlConstants";
import { AppContext } from "../../../../Common/contexts/AppContext";
import { AppDetails } from "../../../../Common/models/AppDetails";
import { processFlowEventEmitter } from "../../../EventEmitter";
import { CodeVerificationResponse } from "../../../common/models/CodeVerificationResponse";

interface KfsScreenProps {
  kfsDocumentprops: KfsDocumentProps;
}

type FormValues = {
  otp: string
}

async function executeAction(appId: number, otp: number) {
  const response = await AXIOS.post<CodeVerificationResponse>(ACTION_APP_URL(appId) + "/kfs/verify", { "code": otp }).then((res) => { return res; }).catch(() => { return { data: null }; });
  return response.data;
}

export const KfsFormScreen: React.FC<KfsScreenProps> = (props) => {
  const form = useForm<FormValues>({ mode: "onSubmit" });
  const { control, handleSubmit, setFocus, setError, setValue, formState: { errors, isSubmitting } } = form;
  const appDetails: AppDetails = useContext(AppContext);
  const formSubmitCallback = useCallback(async () => {
    await handleSubmit(onClickHandler)();
  }, []);

  const onClickHandler = async (data: any) => {
    const response = await executeAction(appDetails.applicationId, data.otp);

    if (response?.result === "SUCCESS") {
      processFlowEventEmitter.emit("STEP_COMPLETED", "KFS_CONSENT_ACCEPTED");
    } else {
      setError("otp", { message: response?.errorMessage });
    }
  };

  const formDetails: FormDetails = {
    control: control,
    errors: errors,
    setFocus: setFocus,
    isSubmitting: isSubmitting,
    setValue: setValue
  };

  return (
    <BaseLayoutWithBar>
      <ActionAreaLayout
        header="Instant Personal Loan Disbursal"
        buttonLabel="Confirm"
        onClick={formSubmitCallback}
        isSubmitting={isSubmitting}>
        <>
          <FormContextProvider formDetails={formDetails}>
            <KfsFormFields formSubmitCallback={formSubmitCallback} />
          </FormContextProvider>
          <br />
        </>
        <>
          <div className="text-align-center mb-xl">
            By confirming the code, you accept the following loan key fact statement.
          </div>
          <KfsTable {...props.kfsDocumentprops} />
        </>
      </ActionAreaLayout>
    </BaseLayoutWithBar>
  );
};