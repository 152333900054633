import React, { useContext } from "react";
import { Grid } from "@bb/wc-ui-framework";
import { LayoutProps, TextInput } from "../elements/TextInput";
import { AppContext } from "../contexts/AppContext";

interface PhoneNumberFieldProps {
  name: string;
}

export const PhoneNumber: React.FC<PhoneNumberFieldProps> = (props) => {

  const context = useContext(AppContext);

  const layout: LayoutProps = {
    name: props.name,
    fluid: true,
    icon: "mobile",
    type: "text",
    transparent: true,
    disabled: true,
    value: context.maskedMobile,
    size: "large"
  };

  return (
    <>
      <Grid centered className="m-z">
        <Grid.Column
          mobile={14}
          tablet={6}
          computer={6}
          className="text-align-center"
        >
          <div className="position-relative clearfix">
            <TextInput layoutProps={layout} />
          </div>
        </Grid.Column>
      </Grid>
    </>
  );
};