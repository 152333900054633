import React, { useCallback, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { AuthenticationBasisToken } from "../../../Authentication/components/AuthenticationComponent";
import { Process } from "./ApplicationFlowComponent";
import { BaseLayout } from "../../../Common/layouts/GenericLayout";
import { AppDetails } from "../../../Common/models/AppDetails";
import { AuthResponse } from "../../../Authentication/models/AuthResponse";
import ErrorBoundary from "../../../Common/components/ErrorBoundary";
import { AppContextProvider } from "../../../Common/contexts/AppContext";
import { ErrorScreen } from "../../../Common/viewComponents/ErrorScreen";
import { Loading } from "../../../Common/viewComponents/Loading";
import AXIOS from "../../../Common/util/ApiUtils";
import { FETCH_APP_DETAILS_URL } from "../../../Common/util/UrlConstants";

async function fetchAppDetails(appId: string) {
  const response = await AXIOS.get<AppDetails>(FETCH_APP_DETAILS_URL(appId))
    .then((res) => { return res; })
    .catch((err) => {
      console.error("Error - " + err);
      return {
        data: null
      };
    });
  return response.data;
}

export const ApplicationEntryWrapper: React.FC = () => {
  const { appId } = useParams();

  if (appId === undefined || isNaN(Number(appId))) {
    return <ErrorScreen msg="" />;
  }

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  if (token !== null && token !== undefined) {
    if (decodeURI(token) !== token) {
      return <ErrorScreen msg="" />;
    }
  }

  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [appDetails, setAppDetails] = useState<AppDetails | null>(null);
  const authorisedBasisSession = sessionStorage.getItem("AUTHORISED");
  const [fetchingAppDetails, setFetchingAppDetails] = useState<boolean>(token === null && authorisedBasisSession === appId);

  const authCallback = useCallback((authResponse: AuthResponse) => {
    setAppDetails(authResponse?.appDetails);
    setAuthenticated(authResponse?.result);
    if (authResponse?.appDetails?.applicationId !== null && authResponse?.appDetails?.applicationId !== undefined) {
      sessionStorage.setItem("AUTHORISED", authResponse.appDetails.applicationId.toString());
    }
  }, []);

  useEffect(() => {
    if (appId === undefined) {
      setFetchingAppDetails(false);
    }
    if (fetchingAppDetails && appId !== undefined) {
      fetchAppDetails(appId).then((res) => {
        setAppDetails(res);
        setAuthenticated(true);
        setFetchingAppDetails(false);
      });
    }
  }, [fetchingAppDetails]);

  if ((authenticated || fetchingAppDetails) && appId != undefined && appId !== null) {

    if (fetchingAppDetails) {
      return <Loading />;
    }

    if (appDetails === null) {
      return <ErrorScreen msg="Error Fetching app" />;
    }

    return (
      <ErrorBoundary>
        <AppContextProvider appDetails={appDetails}>
          <BaseLayout children={<Process />} />
        </AppContextProvider>
      </ErrorBoundary>
    );
  } else {
    return <AuthenticationBasisToken appId={appId} token={token} authenticated={authenticated} onAuthentication={authCallback} />;
  }
};