import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ApplicationEntryWrapper } from "./Process/common/components/ApplicationEntryWrapper";
import { ApplicationRedirectEntryWrapper } from "./Process/common/components/ApplicationRedirectEntryWrapper";

export const App: React.FC = () => {

  //TODO : Add config call

  return (
    <BrowserRouter>
      <div>
        <Routes>
          <Route path="/app/:appId" element={<ApplicationEntryWrapper />} />
          <Route path="/app/redirect/:callbackSource" element={<ApplicationRedirectEntryWrapper />} />
        </Routes>
      </div>
    </BrowserRouter>
  );
};

export default App;
