import React, { useContext, useEffect, useState } from "react";
import AXIOS from "../../../Common/util/ApiUtils";
import { ACTION_APP_URL } from "../../../Common/util/UrlConstants";
import { Loading } from "../../../Common/viewComponents/Loading";
import { KfsActionResponse } from "../models/KfsActionResponse";
import { AppContext } from "../../../Common/contexts/AppContext";
import { Congratulations } from "../../congratulations/Congratulations";
import { ErrorScreen } from "../../../Common/viewComponents/ErrorScreen";
import { KfsFormScreen } from "../views/base/KfsScreen";

async function executeAction(appId: number) {
  const response = await AXIOS.post<KfsActionResponse>(ACTION_APP_URL(appId) + "/kfs/summary").then((res) => { return res; }).catch(() => { return { data: null }; });
  return response.data;
}

export const KfsFlow: React.FC = () => {

  const appDetails = useContext(AppContext);
  const [processed, setProcessed] = useState<boolean>(false);
  const [actionResponse, setActionResponse] = useState<KfsActionResponse | null>(null);

  useEffect(() => {
    executeAction(appDetails.applicationId).then((res) => {
      setActionResponse(res);
      setProcessed(true);
    });
  }, [appDetails.applicationId]);


  if (actionResponse !== null) {
    if (actionResponse.result === "SUCCESS"
      && actionResponse.kfsDetails !== undefined
      && actionResponse.nodalAndLspDetails !== undefined
      && actionResponse.repaymentSchedule !== undefined) {
      return <KfsFormScreen kfsDocumentprops={{
        "kfsDocument": actionResponse.kfsDetails,
        "nodalAndLspDetails": actionResponse.nodalAndLspDetails,
        "repaymentSchedule": actionResponse.repaymentSchedule
      }} />;
    } else if (actionResponse.result === "ERROR") {
      return (
        <div data-testid="kfs-error">
          <ErrorScreen msg="Error proceeding for Key Facts Summary verification. Please try again after some time.." />
        </div>
      );
    } else {
      return <Congratulations />;
    }
  } else if (processed) {
    return (
      <div data-testid="kfs-error">
        <ErrorScreen msg="Error proceeding for Key Facts Summary verification. Please try again after some time..." />
      </div>
    );
  } else {
    return <Loading />;
  }
};