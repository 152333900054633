import React from "react";
import { OtpField } from "../../../../Common/controls/OtpField";
import { PhoneNumber } from "../../../../Common/controls/PhoneNumber";

interface Props {
  formSubmitCallback: any;
}

export const PartnerCodeVerificationFormFields: React.FC<Props> = (props) => {
  const tooltipText = "Please enter the code sent by RBL Bank";

  const rules = {
    required: "Please enter the code.",
    pattern: {
      value: /^[0-9]+$/,
      message: tooltipText,
    },
    minLength: {
      value: 6,
      message: "Please enter the 6-digit code."
    }
  };

  return (
    <>
      <div data-testid="partnercodeverification">
        <PhoneNumber name="phone" />
        <OtpField name="otp" placeholderText="Enter Code Here" rules={rules} tooltipText={tooltipText}
          otpSubmitButtonAction={props.formSubmitCallback} otpTriggerGap={30} otpLength={6} resendOtpUrl="/partner-code/resend-code" />
      </div>
    </>
  );
};