import React, { useCallback, useContext, useEffect, useReducer, useState } from "react";
import { Grid, Button } from "@bb/wc-ui-framework";
import { LayoutProps, TextInput } from "../elements/TextInput";
import { Tooltip } from "../elements/Tooltip";
import { FormContext } from "../contexts/FormContext";
import { default as Style } from "../Styles/Controls.module.scss";
import AXIOS from "../util/ApiUtils";
import { ACTION_APP_URL } from "../util/UrlConstants";
import { AppContext } from "../contexts/AppContext";
import { ErrorLabel } from "../viewComponents/ErrorScreen";

interface OtpFieldProps {
  name: string;
  otpLength?: number;
  placeholderText: string;
  rules: any;
  otpSubmitButtonAction: any;
  otpTriggerGap: number;
  resendOtpUrl: string;
  tooltipText: string;
}

interface ResendOtpSegmentProps {
  timerText: string;
  buttonText: string;
  timerDuration: number;
  resendOtpUrl: string;
  additionalAction: any;
}

export interface ResendOtpResponse {
  result: string;
  errorMessage?: string;
}

async function executeResendOtpAction(appId: number, resendUrl: string) {
  const response = await AXIOS.post<ResendOtpResponse>(ACTION_APP_URL(appId) + resendUrl).then((res) => { return res; }).catch(() => { return { data: { result: "FAILURE" } }; });
  return response.data;
}

export const OtpField: React.FC<OtpFieldProps> = (props) => {
  const [reducer, updateReducer] = useReducer((x) => x + 1, 0);

  const context = useContext(FormContext);

  const layout: LayoutProps = {
    name: props.name,
    fluid: true,
    icon: "lock",
    type: "tel",
    transparent: true,
    placeholder: props.placeholderText,
    maxLength: props.otpLength,
    minLength: props.otpLength,
    size: "large"
  };

  return (
    <>
      <Grid centered className="m-z">
        <Grid.Column mobile={14} tablet={6} computer={6} className="text-align-center">
          <Tooltip content={props.tooltipText} rerender={reducer} trigger={
            <div className="position-relative clearfix" style={{ marginBottom: "60px" }}>
              <TextInput
                rulesProps={{ "rules": props.rules }}
                layoutProps={layout}
              />
              <Button disabled={context.isSubmitting} color="green" className="br-z" style={{ position: "absolute", bottom: "0px", right: "-3px" }}
                onClick={props.otpSubmitButtonAction} icon='chevron right' />
            </div>
          } />
          <ResendOtpSegment additionalAction={updateReducer} timerText="Resend code in " buttonText="Resend Code" timerDuration={props.otpTriggerGap} resendOtpUrl={props.resendOtpUrl} />
        </Grid.Column>
      </Grid>
    </>
  );
};

const ResendOtpSegment: React.FC<ResendOtpSegmentProps> = (props) => {

  const [timer, setTimer] = useState<number>(props.timerDuration);
  const [resendError, setResendError] = useState<boolean>(false);
  const [resendOtpResponse, setResendOtpResponse] = useState<ResendOtpResponse>();
  const timeOutCallback = useCallback(() => setTimer((currTimer) => currTimer - 1), []);

  useEffect(() => {
    if (timer > 0) {
      setTimeout(timeOutCallback, 1000);
    }
  }, [timer, timeOutCallback]);

  const appDetails = useContext(AppContext);
  const resendOtp = async () => {
    setTimer(props.timerDuration);
    setResendError(false);
    props.additionalAction();
    const resendStatus = await executeResendOtpAction(appDetails.applicationId, props.resendOtpUrl);
    setResendOtpResponse(resendStatus);
    setResendError(resendStatus.result === "FAILURE");
  };

  const style = { display: "block", borderRadius: 5, margin: "20px auto", padding: "10px 20px", border: "1px solid #ccc", maxWidth: "fit-content" };

  let element;

  if (timer > 0) {
    element =
      <span className="resendOTPText" style={style}>
        {props.timerText} <span className={Style.loadingSecond}>{timer}</span> seconds
      </span>;
  } else {
    element =
      <span className="resendOTPLink cursor-pointer" style={style} onMouseDown={() => resendOtp()}>
        {props.buttonText}
      </span>;
  }

  return (
    <>
      {element}
      {resendError && <ErrorLabel content={resendOtpResponse?.errorMessage || "Error sending OTP. Please try again later."} />}
    </>
  );
};