import { toHTMLText } from "./StringUtils";

interface TableColumn {
  text: string;
  width: number | null;
}

export interface Row {
  columns: Array<TableColumn>;
}

export const renderBodyRow = (row: Row, index: number) => {
  const cells = [];
  cells.push({ content: index + 1, key: 0, width: 1 });
  row?.columns?.forEach((cell, i) => {
    cells.push({ content: toHTMLText(cell.text), key: i + 1, width: cell.width });
  });
  return ({
    key: index,
    cells: cells
  });
};