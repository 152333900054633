import { AUTHORISE_APP_ACCESS_URL, AUTHORISE_APP_ACCESS_BASIS_SESSION_URL } from "../../Common/util/UrlConstants";
import AXIOS, { AXIOS_FORM } from "../../Common/util/ApiUtils";
import React, { useEffect, useState } from "react";
import { AuthResponse } from "../models/AuthResponse";
import { Loading } from "../../Common/viewComponents/Loading";
import { Unauthorised } from "../viewComponents/Unauthorised";

const authoriseAppAccess = async (appId: string, token: string): Promise<AuthResponse> => {
  const response = await AXIOS_FORM.post<AuthResponse>(AUTHORISE_APP_ACCESS_URL, createAuthFormData(appId, token));
  return response.data;
};

const authoriseAppAccessBasisSession = async (appId: string): Promise<AuthResponse> => {
  const response = await AXIOS.get<AuthResponse>(AUTHORISE_APP_ACCESS_BASIS_SESSION_URL(appId));
  return response.data;
};

function createAuthFormData(appId: string, token: string) {
  const formData = new FormData();
  formData.append("username", appId);
  formData.append("password", token);
  return formData;
}

interface AuthenticationProps {
  appId: string | undefined | null,
  token: string | null,
  authenticated: boolean,
  onAuthentication: (v: AuthResponse) => void;
}

export const AuthenticationBasisToken: React.FC<AuthenticationProps> = (props) => {

  const {appId, token, onAuthentication, authenticated} = props;

  const [inProgress, setInProgress] = useState<boolean>(true);

  //Authorise app access
  useEffect(() => {
    if (appId != undefined && appId !== null && token !== null && token !== "" && !authenticated) {
      authoriseAppAccess(appId, token).then((res) => {
        onAuthentication(res);
        setInProgress(false);
      });
    } else {
      setInProgress(false);
      onAuthentication({ result: false, appDetails: null });
    }
  }, [appId, token, onAuthentication]);

  if (authenticated || inProgress) {
    return <Loading />;
  } else {
    return <Unauthorised />;
  }
};

export const AuthenticationBasisSession: React.FC<AuthenticationProps> = (props) => {

  const {appId, onAuthentication, authenticated} = props;

  const [inProgress, setInProgress] = useState<boolean>(true);

  //Authorise app access
  useEffect(() => {
    if (appId !== undefined && appId !== null) {
      authoriseAppAccessBasisSession(appId).then((res) => {
        onAuthentication(res);
        setInProgress(false);
      });
    } else {
      setInProgress(false);
      onAuthentication({ result: false, appDetails: null });
    }
  }, [appId, onAuthentication]);

  if (authenticated || inProgress) {
    return <Loading />;
  } else {
    return <Unauthorised />;
  }
};