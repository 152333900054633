import React from "react";
import { OtpField } from "../../../../Common/controls/OtpField";
import { PhoneNumber } from "../../../../Common/controls/PhoneNumber";

interface KfsFormFieldsProps {
  formSubmitCallback: any;
}

export const KfsFormFields: React.FC<KfsFormFieldsProps> = (props) => {

  const tooltipText = "Please enter the code sent by IDFC First Bank";

  const rules = {
    required: "Please enter the code.",
    pattern: {
      value: /^[0-9]+$/,
      message: tooltipText,
    },
    minLength: {
      value: 6,
      message: "Please enter the 6-digit code."
    }
  };

  return (
    <>
      <div data-testid="kfscodeverification">
        <PhoneNumber name="phone" />
        <OtpField name="otp" placeholderText="Enter Code Here" rules={rules} tooltipText={tooltipText}
          otpSubmitButtonAction={props.formSubmitCallback} otpTriggerGap={2} otpLength={6} resendOtpUrl="/kfs/resend-code" />
      </div>
    </>
  );
};