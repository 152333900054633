import React from "react";
import { getBankLogo } from "./BankLogo";
import { Menu, Image } from "@bb/wc-ui-framework";

const bbLogo = { key: 1, content: <Image data-testid="bb-logo" style={{ height: "42px" }} width="150" height="42" src="https://static.bankbazaar.com/images/bankbazaar-logo.svg" alt="BankBazaar" />, className: "p" };

interface NavBarProps {
  bankLogoId: number | undefined
}

export const TopBar: React.FC<NavBarProps> = (props) => {
  const logos = [];
  logos.push(bbLogo);
  if (props.bankLogoId !== undefined && props.bankLogoId != 0) {
    logos.push(getBankLogo(props.bankLogoId));
  }
  return (
    <Menu borderless items={logos} data-testid="leftNav" className="m-z" />
  );
};