import React, { useEffect, useState } from "react";
import { Loading } from "../viewComponents/Loading";

export const RedirectForm: React.FC<({ action: string })> = (props) => {

  const [redirected, setRedirected] = useState<boolean>(false);

  useEffect(() => {
    if (!redirected && props.action !== "") {
      window.location.href = props.action;
      setRedirected(true);
    }
  }, [props.action]);

  return (
    <div data-testid="redirectForm" >
      <Loading />
    </div>
  );
};