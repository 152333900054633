import React, { useState } from "react";
import { Grid } from "@bb/wc-ui-framework";
import { ContingentChargesView } from "./ContingentChargesView";
import { DisclaimerView } from "./DisclaimerView";
import { DisclosureView } from "./DisclosureView";
import { SummaryView } from "./SummaryView";
import { KfsDocument } from "../../models/KfsDocument";
import { NodalAndLspDetails } from "../../models/NodalAndLspDetails";
import { RepaymentSchedule } from "../../models/RepaymentSchedule";
import { TableContextProvider } from "../../../../Common/contexts/TableContext";

export interface KfsDocumentProps {
  kfsDocument: KfsDocument;
  nodalAndLspDetails: NodalAndLspDetails;
  repaymentSchedule: Array<RepaymentSchedule>;
}

export const KfsTable: React.FC<KfsDocumentProps> = (props) => {
  const { kfsDocument, nodalAndLspDetails, repaymentSchedule } = props;

  const [active, setActive] = useState<boolean>(true);

  const onClick = () => {
    setActive(!active);
  };

  const kfsForm = (
    <>
      <Grid centered>
        <Grid.Column mobile={16} computer={8} className="p-z">
          <TableContextProvider tableDetails={{ widths: [2, 7, 7], columns: 3 }}>
            <SummaryView active={active} kfsDocument={kfsDocument} />
            <ContingentChargesView active={active} kfsDocument={kfsDocument} />
            <DisclosureView active={active} nodalAndLspDetails={nodalAndLspDetails} />
          </TableContextProvider>
          <DisclaimerView active={active} repaymentSchedule={repaymentSchedule} />
          <div className="text-align-center mb-xl">
            <p className="mt-xl mb-xl">
              <a style={{ cursor: "pointer" }} onClick={onClick}> {active ? "View More (+)" : "View Less (-)"} </a>
            </p>
          </div>
        </Grid.Column>
      </Grid>
    </>
  );

  return (
    <div data-testid="kfsform">
      {kfsForm}
    </div>
  );
};