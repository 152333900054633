import React, { useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import { Button } from "@bb/wc-ui-framework";
import { isMobile } from "react-device-detect";

export const TrackYourApplication: React.FC = () => {
  const appDetails = useContext(AppContext);
  if (appDetails === undefined || appDetails === null) {
    return <div data-testid="track-your-application-invalid" />;
  }

  const props = {
    "classname": "m-z js-donotMove eform-skip-border btn-border font-medium",
    "href": "/help-centre.html?appId=" + appDetails.applicationId,
    "target": "_blank",
    "rel": "noreferrer"
  };

  const buttonElem = <Button as="a" basic content="Track Application Here!" color="green" {...props} />;
  const linkElem = <a {...props} >
    Track Application Here!
  </a>;

  return (
    <div data-testid="track-your-application" className="mb-xl" style={{ marginTop: "30px" }}>
      {isMobile ? linkElem : buttonElem}
    </div>
  );
};