import React from "react";
import { Image } from "@bb/wc-ui-framework";

const bankIdToImage = new Map();
bankIdToImage.set(2, { path: "HDFC", text: "HDFC" });
bankIdToImage.set(50, { path: "https://static.bankbazaar.com/images/common/bank-logo/federal-bank.png", text: "Federal Bank" });
bankIdToImage.set(82, { path: "https://static.bankbazaar.com/images/common/bank-logo/rbl-bank.png", text: "RBL Bank" });
bankIdToImage.set(210, { path: "https://static.bankbazaar.com/images/common/bank-logo/sbi-cards.png", text: "SBI Cards" });
bankIdToImage.set(459, { path: "https://static.bankbazaar.com/images/common/bank-logo/idfc-first-bank.png", text: "IDFC First Bank" });

export function getBankLogo(bankId: number) {
  const obj = bankIdToImage.get(bankId);
  if (obj === undefined) {
    return null;
  }
  return { key: 2, content: <Image height="35" src={obj.path} alt={obj.text} title="" data-testid="bankLogo" />, position: "right", className: "p" };
}

export function getbankImagePath(bankId: number | undefined) {
  if (bankId === undefined) {
    return null;
  }
  const obj = bankIdToImage.get(bankId);
  if (obj === undefined) {
    return null;
  }
  return obj.path;
}