import React from "react";
import { getbankImagePath } from "./BankLogo";
import { default as Style } from "./styles/Redirect.module.css";
import { default as StaticLoaderStyle } from "./styles/Loader.module.css";

export const Loading: React.FC = () => {
  return (
    <div data-testid="loading">
      <div className={StaticLoaderStyle.searchLoader}>
        <img src="https://static.bankbazaar.com/images/common/search_docs.png" /> <br /><br />
        <img src="https://static.bankbazaar.com/images/common/sand-clock.gif" /> <br />
        <p className="font-weight-bold">Processing...</p> <br />
        <p>Please wait while we fetch your details.</p>
        <p className="pl-xl">Do not refresh the window or close the page.</p>
      </div>
    </div>
  );
};

export const KycRedirectLoader: React.FC = () => {
  return (
    <div data-testid="kycredirectloader">
      <Loading />
    </div>
  );
};

export const MandateRedirectLoader: React.FC = () => {
  return (
    <div data-testid="mandateredirectloader">
      <Loading />
    </div>
  );
};

export const PartnerRedirectionLoader = ({ partnerId, subHeader, subHeaderPlacement = "top" }:
  { partnerId: number, subHeader?: string, subHeaderPlacement?: string }) => {
  const Logo = () => {
    return <div className="display-flex align-items-center justify-content-center">
      <img width="100" src="https://static.bankbazaar.com/images/bankbazaar-logo.svg" />
      <img className="pl-xl pr-xl" height="30" src={"https://static.bankbazaar.com/images/common/redirection_animation.gif"} />
      <img width="100" src={getbankImagePath(partnerId)} />
    </div>;
  };
  return <div className={Style.redirectLoaderContainer} data-testid="partnerRedirectLoader">
    <div className="text-align-center m-auto-z">
      {subHeaderPlacement == "top" && <>
        <Logo />
        <p className="mt-xl">{subHeader}</p><p>Do not refresh or close the page</p>
      </>
      }
      <img className="mt-xxl" src={"https://static.bankbazaar.com/images/common/user_with_phone_blue_md.png"} />
      {subHeaderPlacement != "top" && <>
        <p className="mt-xl">{subHeader}</p>
        <Logo />
        <p className="mt-xl font-normal">Do not refresh or close the page</p>
      </>}
    </div>
  </div>;
};