import React from "react";
import { Modal, Header, Table, Button } from "@bb/wc-ui-framework";
import { RepaymentSchedule } from "../../models/RepaymentSchedule";
import { Row, renderBodyRow } from "../../../../Common/util/TableRenderer";

interface PropTypes {
  open: boolean;
  onClose: () => void;
  repaymentSchedule: Array<RepaymentSchedule>;
}

export const RepaymentScheduleModal: React.FC<PropTypes> = ({ open, onClose, repaymentSchedule }) => {

  const getData = (repaymentSchedule: Array<RepaymentSchedule>) => {
    const data: Array<Row> = [];
    repaymentSchedule.forEach((entry) => {
      data.push({
        columns: [
          { text: entry.dueDate, width: null },
          { text: entry.outstandingPrincipal.toString(), width: null },
          { text: entry.installment.toString(), width: null },
          { text: entry.principalComponent.toString(), width: null },
          { text: entry.interestComponent.toString(), width: null }]
      });
    });
    return data;
  };

  return (
    <Modal closeIcon centered open={open} size='large' className="text-align-center p-lg" onClose={onClose}>
      <Header as="h1" className="b-z" content="Repayment Schedule" />
      <div className="mb-xl hfm-table">
        <Table celled columns={6} color="grey" className="m-z darker text-align-center"
          headerRow={["Instl. #", "Due Date", "Opening Balance", "Installment", "Principal", "Interest"]}
          tableData={getData(repaymentSchedule)}
          renderBodyRow={renderBodyRow}
        />
      </div>
      <Button onClick={onClose} type='button' color='green' size='big' content="Ok" />
    </Modal>
  );
};
