import React from "react";
import { Image } from "@bb/wc-ui-framework";
import { isMobile, isAndroid } from "react-device-detect";

export const GooglePlayIcon: React.FC = () => {
  if (!isMobile || !isAndroid) {
    return <></>;
  }

  return (
    <div data-testid="google-play-icon-testid">
      <Image as="a" target="_blank" data-action="click:googlePlayDownload"
        src="https://static.bankbazaar.com/images/common/download_on_the_google_play-v2.png"
        href="https://bnkbzr.page.link/?ofl=https%3A%2F%2Fplay.google.com%2Fstore%2Fapps%2Fdetails%3Fid%3Dcom.bankbazaar.app&apn=com.bankbazaar.app&isi=972595507&ibi=com.bankbazaar.app&utm_campaign=home-page-banner&utm_source=bb-website&amv=162&link=https%3A//www.bankbazaar.com%3Futm_campaign%3Dhome-page-banner%26utm_source%3Dbb-website"
      />
    </div>
  );
};