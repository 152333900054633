import React, { Children, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { Button, Segment, Label } from "@bb/wc-ui-framework";
import { ErrorLabel } from "../viewComponents/ErrorScreen";
import { default as Style } from "../Styles/Layouts.module.scss";

interface LayoutProps {
  children: React.ReactNode;
  header?: string;
  error?: string;
  onClick?: () => void;
  buttonLabel?: string;
  isSubmitting?: boolean
}

export default function ActionAreaLayout({ children, header, error, onClick, buttonLabel, isSubmitting }: LayoutProps) {

  const [mobileHeightWithHeader, setMobileHeightWithHeader] = useState(window.innerHeight - 91);

  useEffect(() => {
    const updateHeightInViewConfiguration = () => {
      setMobileHeightWithHeader(window.innerHeight - 91);
    };

    window.addEventListener("orientationchange", updateHeightInViewConfiguration);
    window.addEventListener("resize", updateHeightInViewConfiguration);

    return () => {
      window.removeEventListener("resize", updateHeightInViewConfiguration);
      window.removeEventListener("orientationchange", updateHeightInViewConfiguration);
    };
  }, []);

  const handleWsCall = async () => {
    if (onClick) {
      await onClick();
    }
  };

  const arrayChildren = Children.toArray(children);
  if (arrayChildren.length == 1) {
    arrayChildren[1] = <div></div>;
  }

  const desktopLayout = (
    <>
      <Segment
        inverted
        attached
        className='b-z p-z'>
        <Segment
          className="p-lg mb-z b-z"
          color="black"
          inverted
          textAlign='center'>
          {header &&
            <h3>{header}</h3>}
          {error &&
            <ErrorLabel content={error} />}
        </Segment>
        <Segment
          inverted
          basic
          textAlign='center'
          style={{ minHeight: "100px", marginTop: "32px" }}>
          {arrayChildren[0]}
        </Segment>
        {onClick &&
          <Segment textAlign='center' basic>
            <Button
              type='button'
              color='green'
              size='large'
              onClick={handleWsCall}
              className={isSubmitting ? Style.progressBarStriped : ""}
              disabled={isSubmitting}
              content={buttonLabel || "Submit"} />
          </Segment>}
        {arrayChildren[1]}
      </Segment>
    </>
  );

  const mobileLayout = (
    <>
      <Segment
        inverted
        padded
        attached
        style={{ height: mobileHeightWithHeader + "px", overflowY: "auto", border: "0", width: "100%" }}>
        {header &&
          <Label
            attached='top'
            color='black'
            size='large'
            className="text-align-center"
            content={header} />}
        {error &&
          <ErrorLabel content={error} />}
        {children}
      </Segment>
      {onClick &&
        <Button
          type='button'
          color='green'
          size='large'
          style={{ borderRadius: "0", marginRight: "0" }}
          onClick={handleWsCall}
          fluid
          className={isSubmitting ? Style.progressBarStriped : ""}
          disabled={isSubmitting}
          content={buttonLabel || "Submit"} />}
    </>
  );

  return isMobile ? mobileLayout : desktopLayout;
}
