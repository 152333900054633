import React, { useContext } from "react";
import { Table } from "@bb/wc-ui-framework";
import { KfsDocument } from "../../models/KfsDocument";
import { getFormattedNumberInCurrency } from "../../../../Common/util/StringUtils";
import { Row, renderBodyRow } from "../../../../Common/util/TableRenderer";
import { TableContext } from "../../../../Common/contexts/TableContext";

interface PropTypes {
  active: boolean;
  kfsDocument: KfsDocument;
}

export const SummaryView = (props: PropTypes) => {
  const tableContext = useContext(TableContext);
  const widths = tableContext.widths;

  const getMinView = (kfsDocumentDetail: KfsDocument) => {
    const rows: Array<Row> = [
      {
        columns: [
          { text: "Date And Place of Execution of the Agreement", width: widths[1] },
          { text: kfsDocumentDetail.agreementDate + " and " + kfsDocumentDetail.agreementPlace, width: widths[2] }]
      },
      {
        columns: [
          { text: "Borrower(s) Details:", width: widths[1] },
          { text: kfsDocumentDetail.borrowerDetail, width: widths[2] }]
      },
      {
        columns: [
          { text: "Bank's Details: Name and Registered Address", width: widths[1] },
          { text: kfsDocumentDetail.bankName, width: widths[2] }]
      },
      {
        columns: [
          { text: "Sanctioned Limit(in Rupees)", width: widths[1] },
          { text: getFormattedNumberInCurrency(kfsDocumentDetail.sanctLimit), width: widths[2] }]
      },
      {
        columns: [
          { text: "Loan Amount(in Rupees)", width: widths[1] },
          { text: getFormattedNumberInCurrency(kfsDocumentDetail.loanAmt), width: widths[2] }]
      },
      {
        columns: [
          { text: "Total interest charge during the entire tenor of loan (in Rupees)", width: widths[1] },
          { text: getFormattedNumberInCurrency(kfsDocumentDetail.totalInt), width: widths[2] }]
      },
      {
        columns: [
          { text: "Processing fee, if any (in Rupees)", width: widths[1] },
          { text: getFormattedNumberInCurrency(kfsDocumentDetail.processFee), width: widths[2] }]
      }
    ];
    return rows;
  };

  const getData = (kfsDocumentDetail: KfsDocument) => {
    const rows: Array<Row> = [
      {
        columns: [
          { text: "Net disbursed amount(in Rupees)", width: widths[1] },
          { text: getFormattedNumberInCurrency(kfsDocumentDetail.netDisbAmt), width: widths[2] }]
      },
      {
        columns: [
          { text: "Total amount to be paid by borrower", width: widths[1] },
          { text: getFormattedNumberInCurrency(kfsDocumentDetail.totalAmtToBePaid), width: widths[2] }]
      },
      {
        columns: [
          { text: "Applicable Rate of Interest", width: widths[1] },
          { text: kfsDocumentDetail?.appRoi?.toString() + "% p.a.", width: widths[2] }]
      },
      {
        columns: [
          { text: "Annual Percentage Rate- Effective Annualized interest rate(in percentage)(computed on net disbursed amount using IRR approach and reducing method)", width: widths[1] },
          { text: kfsDocumentDetail?.apr?.toString() + "% p.a.", width: widths[2] }]
      },
      {
        columns: [
          { text: "Tenor of Loan (in months/days)", width: widths[1] },
          { text: kfsDocumentDetail.tenor + " months", width: widths[2] }]
      },
      {
        columns: [
          { text: "Repayment frequency of the borrower", width: widths[1] },
          { text: (kfsDocumentDetail.repayFreq === "M" ? "Monthly" : kfsDocumentDetail.repayFreq), width: widths[2] }]
      },
      {
        columns: [
          { text: "Number of installments of repayment", width: widths[1] },
          { text: kfsDocumentDetail.numInstallment?.toString(), width: widths[2] }]
      },
      {
        columns: [
          { text: "Amount of each instalments of repayment (in Rupees)", width: widths[1] },
          { text: getFormattedNumberInCurrency(kfsDocumentDetail.amtInstall), width: widths[2] }]
      },
      {
        columns: [
          { text: "Emi Start Date", width: widths[1] },
          { text: kfsDocumentDetail.emiStartDate, width: widths[2] }]
      },
      {
        columns: [
          { text: "Activation Fee (in Rupees)", width: widths[1] },
          { text: "NA", width: widths[2] }]
      }
    ];
    return [...getMinView(kfsDocumentDetail), ...rows];
  };

  return (
    <Table celled columns={tableContext.columns} color="grey" className="m-z darker"
      headerRow={["S.No.", "Parameter", "Details"]}
      tableData={props.active == true ? getMinView(props.kfsDocument) : getData(props.kfsDocument)}
      renderBodyRow={renderBodyRow}
    />
  );
};