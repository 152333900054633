import React from "react";
import { Header, Image, Modal, Segment, Label } from "@bb/wc-ui-framework";


export const ErrorLabel = ({ content }: { content?: string }) => {
  const errorImageProps = {
    avatar: true,
    spaced: "right",
    src: "https://www.bankbazaar.com/images/icon-error.png",
  };
  return (
    <Label
      className="centered text-align-left animation-bounce fluid position-absolute" style={{left: 0}}
      color='pink'
      image={errorImageProps}
      pointing
      content={content || "Uh oh! Something seems to be wrong. Please try again."} />
  );
};

export const ErrorScreen = ({ msg }: { msg: string | undefined }) => {

  const heading = "Uh-oh! Something went wrong";
  const message = msg || "There seems to be a technical issue.";
  const content = (
    <Segment basic textAlign="center" style={{ color: "#FFFFFF" }}>
      <Image className='m-auto' src="https://static.bankbazaar.com/images/common/technical-error.png" header={heading} />
      {<Header as='h4' inverted content={heading} />}
      <p className="m-z">{message}</p>
    </Segment>
  );
  return (
    <div data-testid="errorScreen">
      <Modal centered basic className="dark" size='small' open={true}
        content={{ content: content }}
      />
    </div>
  );
};