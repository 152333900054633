import * as React from "react";
import { TopBar } from "../viewComponents/TopBar";
import { useContext } from "react";
import { AppContext } from "../contexts/AppContext";

interface BaseProps {
  children: React.ReactNode;
}

export const BaseLayout: React.FC<BaseProps> = (props) => {

  return (
    <React.Fragment>
      {props.children}
    </React.Fragment>
  );
};

export const BaseLayoutWithBar: React.FC<BaseProps> = (props) => {

  const context = useContext(AppContext);

  return (
    <React.Fragment>
      {<TopBar bankLogoId={context?.bankId ?? 0} />}
      {props.children}
    </React.Fragment>
  );
};