import React, { useContext } from "react";
import { Table, Header } from "@bb/wc-ui-framework";
import { NodalAndLspDetails, NodalOfficerDetails } from "../../models/NodalAndLspDetails";
import { Row, renderBodyRow } from "../../../../Common/util/TableRenderer";
import { TableContext } from "../../../../Common/contexts/TableContext";

interface PropTypes {
  active: boolean;
  nodalAndLspDetails: NodalAndLspDetails;
}

function getNodalOfficerDetails(details: Array<NodalOfficerDetails>) {
  if (details == undefined) {
    return "";
  }

  const partnerPno = details[0];
  const partnerNodalOfficer = details[1];
  const bbGrievanceOfficer = details[2];

  let r = "";

  r = r + "Name : " + partnerPno.name + "\n";
  r = r + "Designation : " + partnerPno.designation + "\n";
  r = r + "Address : " + partnerPno.address + "\n";
  r = r + "Email : " + partnerPno.email + "\n";
  r = r + "Call : " + partnerPno.phone + "\n";
  r = r + "\n";
  r = r + "Nodal Officer email : " + partnerNodalOfficer.email + "\n";
  r = r + partnerNodalOfficer.phone + "\n";
  r = r + "\n";
  r = r + "BankBazaar Grievance Officer Name : " + bbGrievanceOfficer.name + "\n";
  r = r + "BankBazaar Grievance Redressal Email ID : " + bbGrievanceOfficer.email + "\n";
  return r;
}

export const DisclosureView = (props: PropTypes) => {
  const tableContext = useContext(TableContext);
  const widths = tableContext.widths;

  const getDisclosure = (nodalAndLspDetails: NodalAndLspDetails) => {

    const rows: Array<Row> = [
      {
        columns: [
          { text: "Cooling off/look-up period during which borrower shall not be charged any penalty on prepayment of loan", width: widths[1] },
          { text: nodalAndLspDetails.coolOffPeriod, width: null }]
      },
      {
        columns: [
          { text: "Details of LSP acting as recovery agent and authorized to approach the borrower", width: widths[1] },
          { text: nodalAndLspDetails.lspDetails, width: null }]
      },
      {
        columns: [
          { text: "Name, designation, address and phone number of nodal grievance redressal officer", width: widths[1] },
          { text: getNodalOfficerDetails(nodalAndLspDetails.nodalOfficerDetails), width: null }]
      }
    ];
    return rows;
  };

  return (
    <div hidden={props.active}>
      <Header as='h4' content="Other Disclosures" />
      <Table celled columns={tableContext.columns}
        tableData={getDisclosure(props.nodalAndLspDetails)}
        renderBodyRow={renderBodyRow}
      />
    </div>
  );
};