import React from "react";
import { Grid, Header, Image } from "@bb/wc-ui-framework";
import { BaseLayoutWithBar } from "../layouts/GenericLayout";
import { TrackYourApplication } from "../elements/TrackYouApplicationLink";
import { GooglePlayIcon } from "../elements/GooglePlayIcon";
import ActionAreaLayout from "../layouts/ActionAreaLayout";
import { isMobile } from "react-device-detect";

interface Props {
  heading?: string;
  subject: string;
  message: string;
  subtext?: string;
}

export const CongratulationsScreen: React.FC<Props> = (props) => {
  const width = !isMobile ? 3 : null;
  return (
    <BaseLayoutWithBar>
      <ActionAreaLayout header={props.heading}>
        <>
          <div data-testid="congratulationsScreen">
            <Grid centered className="m-z" columns={1}>
              <Grid.Column className="p-z" width={width}>
                <Header className='m-z mt-xl md-xl' textAlign='center' as='h1' content="Congratulations!" />
                <Image data-testid="congratulations-image" className="centered mt-xxl md-xxl"
                  src="https://static.bankbazaar.com/images/common/user_with_phone_blue_md.png" alt="Congratulations" />
                <Header className='m-z' textAlign='center' as='h2' content={props.subject} />
                <Header className='mt-xxl' textAlign='center' as='p' content={props.message} />
                <br />
                {props.subtext &&
                  <div data-testid="sub-text-testid">
                    <Header className='m-z font-small grey' textAlign='center' as='h4' content={props.subtext} />
                  </div>}
                <div className="text-align-center">
                  <TrackYourApplication />
                  <GooglePlayIcon />
                </div>
              </Grid.Column>
            </Grid>
          </div>
        </>
      </ActionAreaLayout>
    </BaseLayoutWithBar>
  );
};